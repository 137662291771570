<template>
    <div class="main">
        <div class="main__background">
            <img class="main__background--img" src="../../assets/textures/naranja.png" alt="paint" />
        </div>
        <div class="main__header">
            <h2 class="main__header--title">MI BLOG</h2>
            <p class="main__header--subtitle">ZAIRA BANDY</p>
        </div>
        <div class="group">
            <a class="main__item" :href="item.link" target="_blank" v-for="(item, index) in items" :key="index">
                <div class="main__item--text">
                    <h2 class="item__text--title">{{item.title}}</h2>
                    <p class="item__text--subtitle">{{item.subtitle}}</p>
                </div>
                <div class="main__item--image">
                    <img :src="item.image" alt="" class="item__image--img">
                </div>
            </a>
        </div>
        <div class="footer">
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import Footer from '../../components/Footer.vue';

/** MINIATURAS */
import MinAcr from '../../assets/images/Mins/acrylic.jpg';
import MinAma from '../../assets/images/Mins/amanecer.jpg';
import MinBar from '../../assets/images/Mins/barniz.jpg';
import MinFon from '../../assets/images/Mins/fondo.jpg';
import MinPal from '../../assets/images/Mins/paleta.jpg';


export default {
  data() {
    return {
        items: [
            {
                title: 'Acrylic Pouring: Cuadro grande',
                subtitle: 'Acompáñame a pintar este cuadro bastante grande con la técnica de Acrylic Pouring.',
                image: MinAcr,
                link: 'https://www.youtube.com/watch?v=NyH1cFDyqZ4'
            },
            {
                title: 'Aprender cómo hacer una paleta de vidrio',
                subtitle: 'Te voy a enseñar cómo hacer una paleta de vidrio para poder utilizarla al pintar con óleo. Te doy un gran secreto para que nunca se te vuelvan a secar los óleos.',
                image: MinPal,
                link: 'https://www.youtube.com/watch?v=YHEdaJTwUZ8'
            },
            {
                title: 'Barniza tus cuadros como un profesional',
                subtitle: 'En este tutorial te voy a recomendar dos barnices para poder barnizar tus cuadros al óleo. ',
                image: MinBar,
                link: 'https://www.youtube.com/watch?v=aQcl0mCM_Xg'
            },
            {
                title: '¡Pintemos un increíble atardecer!',
                subtitle: 'Si alguna vez te ha interesado pintar un atardecer de playa, te sugiero que veas mi tutorial completo. Explico todo mi proceso creativo y te doy tips para ayudarte a pintar mejores atardeceres',
                image: MinAma,
                link: 'https://www.youtube.com/watch?v=4Jt77YyFYlA'
            },
            {
                title: 'Tu artista: ven a pintar un fondo conmigo',
                subtitle: 'En este tutorial te enseño cómo pintar un fondo para tus fotografías. Esto lo ocupan los fotógrafos como fondo tanto para fotografías de personas como de productos. Puedes pintar uno grande y también uno gigante de 5 metros. acompáñame y aprende una técnica nueva.',
                image: MinFon,
                link: 'https://www.youtube.com/watch?v=gGFKVK99EU0'
            }
        ],
    }
  },
  components: {
    Footer
  }
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--bg-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}

/**-----------------BLOG ITEMS-------------------------*/
.group {
    display: flex;
    flex-wrap: wrap;
    padding: 50px;
    margin-top: -150px;
    z-index: 1500;
    justify-content: space-evenly;
    align-content: center;
}
.main__item{
    width: auto;
    text-align: center;
    margin: 30px 10px;
    text-decoration: none;
    z-index: 150;
}
.main__item:hover {
    cursor: pointer;
}
.main__item--text{
    display: block;
    text-align: left;
    width: 400px;
    height: 400px;
    background: var(--bk-color);
    color: var(--txt-color);
    padding: 50px;
    margin-bottom: 50px;
    transition: color ease-in-out 1s;
}
.main__item--text:hover {
    color: rgb(107, 107, 107);
}
.item__text--title{
    font-size: 25px;
    font-weight: 700;
    width: 100%;
}
.item__text--subtitle{
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
}
.main__item--image{
    display: block;
    margin: 10px;
}
.item__image--img{
    width: 420px;
    height: 236px;
    object-fit: cover;
    margin-top: -200px;
}
@media (max-width: 800px){
  .main__item--text{
    width: 200px;
    height: 200px;
  }
  .item__image--img{
    width: 220px;
    height: 123px;
  }
  .item__text--title{
      font-size: 15px;
  }
  .item__text--subtitle{
      font-size: 10px;
      line-height: 13px;
  }
}
</style>